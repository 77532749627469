import React from 'react';
import { Link } from 'gatsby';
import { Button, Space } from 'antd';
import {
  AppstoreAddOutlined,
  NotificationOutlined,
  InstagramFilled,
  FileImageOutlined,
  EyeOutlined,
  TeamOutlined
} from '@ant-design/icons';

// import './homepage.css';

// 首页 新建购买记录，查询修改购买记录导航按钮

const WxStoreNav = () => {
  return (
    <div className="home-page-div">
      <Space direction="vertical" size="large">
        <Link className="nav-link" to="/wxbanner">
          <Button type="primary" size="large" icon={<AppstoreAddOutlined />}>首页轮播图</Button>
        </Link>
        <Link className="nav-link" to="/wxchannels">
          <Button type="primary" size="large" icon={<EyeOutlined />}>频道管理</Button>
        </Link>
        <Link className="nav-link" to="/wxcategory">
          <Button type="primary" size="large" icon={<FileImageOutlined />}>商品分类(热搜词)</Button>
        </Link>
        <Link className="nav-link" to="/wxnotices">
          <Button type="primary" size="large" icon={<NotificationOutlined />}>通知管理</Button>
        </Link>
        <Link className="nav-link" to="/wxgoods">
          <Button type="primary" size="large" icon={<InstagramFilled />}>商品管理</Button>
        </Link>
        <Link className="nav-link" to="/wxspecialgoods">
          <Button type="primary" size="large" icon={<InstagramFilled />}>特价商品管理</Button>
        </Link>
        <Link className="nav-link" to="/wxpayaccount">
          <Button type="primary" size="large" icon={<EyeOutlined />}>支付账户</Button>
        </Link>
        <Link className="nav-link" to="/grouppurchasecommander">
          <Button type="primary" size="large" icon={<TeamOutlined />}>团购团长</Button>
        </Link>
      </Space>
    </div>
  )
}

export default WxStoreNav;
