import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import WxStoreNav from '../components/page_wx_store';

const StoreManagePage = () => (
  <SiteLayout>
    <SEO title="StoreManage" />
    <WxStoreNav></WxStoreNav>
  </SiteLayout>
)

export default StoreManagePage;
