import React, { useState, useEffect } from 'react';

import { RequestAdminGet } from './layout_requests';
import Header from './header';
import Footer from './footer';

import './index.css';
import 'antd/dist/antd.css';

const SiteLayout = ({ children }) => {
  const [requestCount, setRequestCount] = useState(0);
  
  useEffect(() => { setRequestCount(requestCount + 1); }, []);

  return (
    <div className="flex-container">
      <Header></Header>
      <RequestAdminGet requestCount={requestCount}></RequestAdminGet>
      <div className="flex-main-container">{children}</div>
      <Footer></Footer>
    </div>
  )
}

export default SiteLayout;
